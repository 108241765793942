<template>
  <div
    class="app-layout"
    :class="{ 'sidebar-open': $store.state.utils.isSidebarOpen }"
  >
    <Sidebar />
    <div class="content">
      <Header />
      <router-view />
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, defineAsyncComponent } from 'vue';
export default defineComponent({
  components: {
    Sidebar: defineAsyncComponent(
      () => import('@/components/dashboard/Sidebar.vue'),
    ),
    Header: defineAsyncComponent(
      () => import('@/components/dashboard/Header.vue'),
    ),
  },
});
</script>
