
import {
  defineComponent,
  defineAsyncComponent,
  onBeforeMount,
  onMounted,
  ref,
} from 'vue';
import investmentModule from '@/modules/investment/investmentModule';
import sessionModule from '@/modules/auth/sessionModule';
export default defineComponent({
  components: {
    Welcome: defineAsyncComponent(
      () => import('@/components/dashboard/Welcome.vue'),
    ),
    Portfolio: defineAsyncComponent(
      () => import('@/components/investment/Portfolio.vue'),
    ),
    ActiveInvestment: defineAsyncComponent(
      () => import('@/components/investment/ActiveInvestment.vue'),
    ),
  },
  setup: () => {
    const { getAllInvestment, allBanks } = investmentModule();
    const { isProfileCompleted } = sessionModule();

    const accountVerification = ref<any>(null);

    onBeforeMount(() => {
      getAllInvestment();
      allBanks();
    });

    onMounted(() => {
      setTimeout(() => {
        if (!isProfileCompleted?.value) {
          accountVerification.value?.open();
        }
      }, 1000);
    });

    return { accountVerification };
  },
});
