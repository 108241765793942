
import { defineComponent, defineAsyncComponent } from 'vue';
export default defineComponent({
  components: {
    Sidebar: defineAsyncComponent(
      () => import('@/components/dashboard/Sidebar.vue'),
    ),
    Header: defineAsyncComponent(
      () => import('@/components/dashboard/Header.vue'),
    ),
  },
});
